import React from 'react';
import './Header.css';
import {ReactComponent as Logo} from '../../assets/logo-dark.svg';

const Header = () => {
    return (
        <header className="header">
            <div className="logo-container">
                <a href="/">
                    <Logo />
                </a>
            </div>
            {/*<nav className="navigation">
                <a href="/shop" className="nav-link">Shop</a>
                <a href="/datasets" className="nav-link">Datasets</a>
                <a href="/spaces" className="nav-link">Spaces</a>
                {/* ... other navigation links */}
            {/*</nav>*/}
            <div className="user-actions">
            {/* Replace these with actual links or buttons as required */}
            <a href="https://www.twitter.com/bitquest_io" className="nav-link">𝕏</a>
            {/* ... other user actions */}
        </div>
    </header>
    );
};

export default Header