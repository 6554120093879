import React from 'react';
import './Home.css';

const Home = () => {
    return (
        <div class="container">
            <div class="typing-container">
                <h1>Coming Soon</h1>
            </div>
        </div>
    );
};

export default Home